import React, { useEffect, useState } from 'react';
import './TakeAction.css';

function TakeAction() {

  useEffect(() => {
    // Get all elements with the "reveal" class
    const reveals = document.querySelectorAll(".reveal");

    // Function to check if elements are in the viewport
    const reveal = () => {
      const windowHeight = window.innerHeight;

      for (let i = 0; i < reveals.length; i++) {
        const elementTop = reveals[i].getBoundingClientRect().top;
        const elementVisible = 150;

        if (elementTop < windowHeight - elementVisible) {
          reveals[i].classList.add("active");
        } else {
          reveals[i].classList.remove("active");
        }
      }
    };

    // Initial call to reveal elements
    reveal();

    // Add an event listener to the window for scroll events
    window.addEventListener("scroll", reveal);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", reveal);
    };
  }, []);

  return (
    <div className='take-action-body' id="take-action">
      <div className="cta-container reveal">
        <div className="cta-content">
          <h2 className="cta-header">Imaginația ta, planul nostru</h2>
          <p className="cta-text">Reducerea decalajului dintre imaginație și realitate. Începe-ți aventura arhitecturală cu noi.</p>
          <div className="cta-button-group">
            <a href={"/contact"} className="cta-button secondary">Contactează-ne</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TakeAction;