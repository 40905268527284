import React from 'react';
import './Footer.css';

function Footer() {
  return(
    <div className='footer'>

    <div className='row'>

      <div className='col footer-logo-text'>
        <img src="/logo.png" className='footer-logo'/>
        <p>Servicii de proiectare, <br /> arhitectură, urbanism<br />   și inginerie.</p>
      </div>

      <div className='col footer-locatie'>
        <h3>Locație <div className="underline"><span></span></div></h3>
        <p>Romania, Arad</p>
        <p>Aurel Crișan. Nr. 7 </p>
        <p className='email-id'>neriaarhitecture@gmail.com</p>
      </div>

      <div className='col footer-meniu'>
        <h3>Meniu <div className="underline"><span></span></div></h3>
        <ul>
          <li><a href="#">Acasă</a></li>
          <li><a href="#despre-body">Despre noi</a></li>
          <li><a href="/portofolio">Portofoliu</a></li>
          <li><a href="/contact">Contact</a></li>
        </ul>
      </div>

      <div className='col footer-urmareste'>
        <h3>Urmărește-ne<div className="underline"><span></span></div></h3>
        <div className="social-icons">
          <a href="https://www.facebook.com/profile.php?id=100054414163571"><i className="fa-brands fa-facebook"></i></a>
          <a href="#"><i className="fa-brands fa-instagram"></i></a>
        </div>
      </div>

    </div>
      <hr></hr>
      <p className='copyright'>Neria Architecture &copy; 2023 - All Rights Reserved</p>
  </div>
  );
}

export default Footer;