import React, {useEffect, useState} from "react";
import './Welcome.css';

function Welcome() {

    return (
        <div className="welcome">
          <div className="welcome-elements">
            <div className="welcome-title">
              <h1>SERVICII DE</h1>
            </div>
            <div className="welcome-description">
              <p>PROIECTARE | ARHITECTURĂ | URBANISM | INGINERIE</p>
            </div>
          </div>
          <div className="welcome-arrow-down">
            <a href="#despre-body"><i className="bi bi-arrow-down-circle"></i></a>
          </div>
        </div>
    );
}

export default Welcome;