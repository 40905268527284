import React from "react";
import {useState} from "react";
import './Navbar.css';
import { Link} from "react-router-dom";
function Navbar() {

  return(
    <div className="navbar">
      <div className="navbar-logo">
        <img src="/logo.png"/>
        <h2>Neria Architecture</h2>
      </div>
      <div className="navbar-links">
        <a href="/">Acasă</a>
        <a href="#despre-body">Despre noi</a>
        <a href="/portofolio">Portofoliu</a>
        <a href="/contact">Contact</a>
      </div>
      <div className="navbar-links-menu">
        <div className="dropdown">
          <a href="#" className="dropdown-link">Meniu</a>
          <div className="dropdown-content">
            <a href="/">Acasă</a>
            <a href="#despre-body">Despre noi</a>
            <a href="/portofolio">Portofoliu</a>
            <a href="/contact">Contact</a>
          </div>
        </div>
      </div>
      
    </div>
  );
}

export default Navbar;