import React, { useEffect, useState } from 'react';
import './Despre.css';

function Despre() {

  useEffect(() => {
    // Get all elements with the "reveal" class
    const reveals = document.querySelectorAll(".reveal");

    // Function to check if elements are in the viewport
    const reveal = () => {
      const windowHeight = window.innerHeight;

      for (let i = 0; i < reveals.length; i++) {
        const elementTop = reveals[i].getBoundingClientRect().top;
        const elementVisible = 150;

        if (elementTop < windowHeight - elementVisible) {
          reveals[i].classList.add("active");
        } else {
          reveals[i].classList.remove("active");
        }
      }
    };

    // Initial call to reveal elements
    reveal();

    // Add an event listener to the window for scroll events
    window.addEventListener("scroll", reveal);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", reveal);
    };
  }, []);

    return (
        <div className="despre-body" id="despre-body">
          <div className="despre-content reveal">
            <div className="despre-image">
              <img src="/logo.png" />
            </div>
            <div className="despre-text">
              <span>Despre Noi</span>
              <h2>Neria Arhitecture</h2>
              <p> La Neria Arhitecture, transformăm viziunile în realități arhitecturale. Cu peste 12 ani de experiență, combinăm expertiza tehnică cu sensibilitatea artistică pentru a crea spații care inspiră și rezistă în timp.
              </p>
              <p>  Echipa noastră, formată din arhitecți talentați și pasionați, îmbină expertiza tehnică cu sensibilitatea artistică.
              </p>
              <p>Lucrăm îndeaproape cu clienții noștri pentru a înțelege nevoile și dorințele lor, oferindu-le soluții personalizate și un serviciu de neegalat.</p>
            </div>
          </div>
          <div className="despre-arrow-down">
              <a href="#reviews-body"><i className="bi bi-arrow-down-circle"></i></a>
          </div>
          
        </div>
    );
}

export default Despre;