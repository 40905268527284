import React, { useEffect, useState } from 'react';
import './Reviews.css';
import pic1 from '../../images/pic-1.jpg';
import pic2 from '../../images/pic-2.jpeg';
import pic3 from '../../images/pic-3.jpg';

function Reviews() {

  useEffect(() => {
    // Get all elements with the "reveal" class
    const reveals = document.querySelectorAll(".reveal");

    // Function to check if elements are in the viewport
    const reveal = () => {
      const windowHeight = window.innerHeight;

      for (let i = 0; i < reveals.length; i++) {
        const elementTop = reveals[i].getBoundingClientRect().top;
        const elementVisible = 150;

        if (elementTop < windowHeight - elementVisible) {
          reveals[i].classList.add("active");
        } else {
          reveals[i].classList.remove("active");
        }
      }
    };

    // Initial call to reveal elements
    reveal();

    // Add an event listener to the window for scroll events
    window.addEventListener("scroll", reveal);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", reveal);
    };
  }, []);

    return (
        <div className="reviews-body" id="reviews-body">
          <div className="reviews-content reveal">
            <div className="reviews-content__left">
              <h2>Nevoia <span>clientului</span> este pe primul loc</h2>
              <p>
              Pentru noi, fiecare proiect începe și se termină cu tine - clientul. Ne dedicăm înțelegerii profunde a aspirațiilor, nevoilor și viziunilor tale.
              </p>
              <p>
              Prin comunicare deschisă și colaborare strânsă, ne asigurăm că rezultatele depășesc așteptările, oferind nu doar un spațiu, ci o experiență personalizată și memorabilă.
              </p>
              <p>
              Pentru Neria Arhitecture, satisfacția ta este măsura adevărată a succesului nostru.
              </p>
             </div>
            <div className="reviews-content__right">
              <div className="card">
                 <img src={pic1} alt="user" />
                 <div className="card__content">
                   <span><i className="ri-double-quotes-l"></i></span>
                   <div className="card__details">
                      <p>"Impresionat de atenția la detalii și profesionalismul celor de la Neria Arhitecture. Designul a depășit așteptările!"</p>
                      <h4>- Radu Ionescu</h4>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <img src={pic2} alt="user" />
                  <div className="card__content">
                    <span><i className="ri-double-quotes-l"></i></span>
                    <div className="card__details">
                      <p>"Echipa Neria Arhitecture a transformat viziunea noastră într-o capodoperă. Serviciu excepțional!"</p>
                      <h4>- Mihai Orădan</h4>
                     </div>
                  </div>
                </div>
                <div className="card">
                  <img src={pic3} alt="user" />
                  <div className="card__content">
                   <span><i className="ri-double-quotes-l"></i></span>
                   <div className="card__details">
                      <p>"Alegerea Neria Arhitecture a fost ideală pentru proiectul nostru. Creativitate și dedicare remarcabile!"</p>
                      <h4>- Andreea Crăciun</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="reviews-arrow-down">
              <a href="#take-action"><i className="bi bi-arrow-down-circle"></i></a>
            </div>
          </div>
    );
}

export default Reviews;