import React, { useEffect, useState } from 'react';
import './Download.css';

function Download() {

  const handleClick = () => {
    const pdfUrl = process.env.PUBLIC_URL + '/OnlineDocument.pdf';
    window.open(pdfUrl, '_blank');
  };

  return (
    <div className='download-body'>
      <div className="download-container">
        <div className="download-content">
          <p className="download-text">Vrei sa descarci formularul pentru tema de proiectare?</p>
          <div className="download-button-group">
            <a onClick={handleClick} className="download-button secondary">Descarcă PDF</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Download;