import React from 'react';
import './Portofolio.css';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import Category from './Category/Category';

function Portofolio() {



  return (
    <>
    <Navbar />
    <Category />
    <Footer />
    </>
  );
}

export default Portofolio;