import React from 'react';
import './Home.css';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import Welcome from './Welcome/Welcome';
import Despre from './Despre/Despre';
import Reviews from './Reviews/Reviews';
import TakeAction from './TakeAction/TakeAction';

function Home() {



  return (
    <>
    <Navbar />
    <Welcome />
    <Despre />
    <Reviews />
    <TakeAction/>
    <Footer />
    </>
  );
}

export default Home;
