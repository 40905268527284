import React from "react";
import './Information.css';

function Information() {
  return (
    <div className="information-body">
      <h1>Informații de contact</h1>
      <div className="information-content">
        <div className="information-text">
          <div className="information-text-details">
            <div className="information-text-details-div">
              <h3><i className="bi bi-telephone"></i>Telefon</h3>
              <p>+40 743 663 264</p>
            </div>
            <div className="information-text-details-div">
              <h3><i className="bi bi-envelope"></i>Email</h3>
              <p>neriaarhitecture@gmail.com</p>
            </div>
            <div className="information-text-details-div">
              <h3><i className="bi bi-bookmark-plus"></i>Urmărește-ne</h3>
              <div className="information-text-details-div-social">
                <a href="https://www.facebook.com/profile.php?id=100054414163571"><i className="fa-brands fa-facebook"></i></a>
                <a href="#"><i className="fa-brands fa-instagram"></i></a>
              </div>
            </div>
            
          </div>
        </div>
        <div className="information-map">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2762.9661477771842!2d21.36460687672131!3d46.171329585980374!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47459958c5704ecb%3A0xea2ac845c57781d8!2sneria%20arhitecture%20srl!5e0!3m2!1sro!2sro!4v1697043865790!5m2!1sro!2sro"></iframe>
        </div>
      </div>
      
    </div>
  );
}

export default Information;