import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Category.css';
import category1 from '../../images/category1.jpg';
import category2 from '../../images/category2.jpg';
import category3 from '../../images/category3.jpg';

function Category() {

  return (
    <div className='category-body'>
    <div className='category-content'>
      <div className='category-images img1'>
        <Link to="/portofolio/arhitectura">
          <div className="overlay"><p>ARHITECTURĂ</p></div>
          <img src={category1} alt="Category 1" />
        </Link>
      </div>
      <div className='category-images img2'>
        <Link to="/portofolio/urbanism">
          <div className="overlay"><p>URBANISM</p></div>
          <img src={category2} alt="Category 2" />
        </Link>
      </div>
      <div className='category-images img3'>
        <Link to="/portofolio/inginerie">
          <div className="overlay"><p>INGINERIE</p></div>
          <img src={category3} alt="Category 3" />
        </Link>
      </div>
    </div>
  </div>
  );
}

export default Category;