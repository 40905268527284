import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Urbanism.css';
import Navbar from '../../Navbar/Navbar';
import Footer from '../../Footer/Footer';

function Urbansim() {

  return (
    <div>
      <Navbar />
      <div className='urbanism-body'>
        <h1>Această pagină este  momentan în lucru...</h1>
        <a href="/">Acasă</a>
      </div>
      <Footer />
    </div>
    
  );
}

export default Urbansim;