import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import {Route, Routes} from 'react-router-dom';
import './index.css';
import Home from './Home/Home';
import Contact from './Contact/Contact';
import Portofolio from './Portofolio/Portofolio';
import Arhitectura from './Portofolio/Arhitectura/Arhitectura';
import Urbansim from './Portofolio/Urbanism/Urbanism';
import Inginerie from './Portofolio/Inginerie/Inginerie';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
  <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/portofolio" element={<Portofolio />} />
      <Route path="/portofolio/arhitectura" element={<Arhitectura />} />
      <Route path="/portofolio/urbanism" element={<Urbansim />} />
      <Route path="/portofolio/inginerie" element={<Inginerie />} />
  </Routes>
</BrowserRouter>
);