import React from 'react';
import './Contact.css';
import Navbar from '../Navbar/Navbar';
import Information from './Information/Information';
import Footer from '../Footer/Footer';
import Download from './Download/Download';

function Contact() {



  return (
    <>
    < Navbar />
    < Information />
    < Download />
    < Footer />
    </>
  );
}

export default Contact;